

.default-layout {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: stretch;
    justify-content: space-between;
}

.content-external-wrapper {
    position: relative;
    min-height: 100px;
    width: 100%;
    background-repeat: repeat;
    background-size: auto;
    flex: 100%;
    z-index: 2;
    transition: all 300ms ease;
}

.talron-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: white;
    padding: 10px 20px;
    z-index: 1;

    .talron-menu-bars {
        position: absolute;
        display: inline-block;
        top: 50%;
        inset-inline-start: 20px;
        width: 17px;
        height: 12px;
        margin-top: -6px;
        line-height:0;
        cursor: pointer;
    }

    svg {
        width: 17px;
        height: 12px;
    }

    .logo {
        height: 40px;
        width: auto;
    }
}

.talron-footer {
    position: relative;
    min-height: 60px;
    padding: 12px 0;
    background: var(--global-secondary-background);
    color: var(--global-inverse-color);
    z-index: 1;

    .footer-inner {
        height: 100%;
    }

    .footer-menu {
        ul {
            list-style: none;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 3px;

            li {
                position: relative;
                padding: 0;
                display: block;

                &:after {
                    content: '|';
                    padding: 3px 10px;

                }

                &:last-of-type::after {
                    display: none;
                }

                a {
                    color: var(--global-inverse-color);
                    font-size: var(--font-xs);
                    line-height: 1;

                    &:hover {
                        color: var(--global-inverse-color);
                        text-decoration: underline;
                    }
                }


            }
        }
    }

    .legal {
        font-size: var(--font-xs);
        color: var(--global-inverse-color);
    }
}

