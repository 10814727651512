
.talron-footer {
  position   : relative;
  min-height : 60px;
  padding    : 12px 0;
  background : var(--global-secondary-background);
  color      : var(--global-inverse-color);
  z-index    : 1;

  .footer-inner {
    height : 100%;
  }

  .footer-menu {
    ul {
      list-style      : none;
      display         : flex;
      justify-content : center;
      flex-wrap       : wrap;
      margin-bottom   : 3px;

      li {
        position : relative;
        padding  : 0;
        display  : block;

        &:after {
          content : '|';
          padding : 3px 10px;

        }

        &:last-of-type::after {
          display : none;
        }

        a {
          color       : var(--global-inverse-color);
          font-size   : var(--font-xs);
          line-height : 1;

          &:hover {
            color           : var(--global-inverse-color);
            text-decoration : underline;
          }
        }


      }
    }
  }

  .legal {
    font-size : var(--font-xs);
    color     : var(--global-inverse-color);
  }
}
