

.default-layout {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: stretch;
    justify-content: space-between;
}



.main-content {
    position: relative;
    min-height: 100px;
    width: 100%;
    background-repeat: repeat;
    background-size: auto;
   // background-image: v-bind(contentBackgroundUrlCss);
    flex: 100%;
    z-index: 2;
    transition: all 300ms ease;

    @media (max-width: 1200px) {
        background-image: v-bind(contentBackgroundUrlCss);
    }

    .main-content-bg {
        position: absolute;
        z-index: 0;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: v-bind(contentBackgroundUrlCss);
        opacity: 0.3;

        @media (min-width: 1200px) {
            display: none
        }
    }
    .main-content-content {
        position: relative;
        z-index: 2;
    }

    &--success {
        //v-bind(contentBackgroundUrlCss)
        background-image:  linear-gradient(45deg, #5DA036 0%, #CFEC86 100%);
        @media (min-width: 1200px) {
            background-image:  v-bind(contentBackgroundUrlCss) linear-gradient(45deg, #5DA036 0%, #CFEC86 100%);
        }
    }

    &--error {
        background-image: linear-gradient(45deg, #FF7878 0%, #FFC3C3 100%);
        @media (min-width: 1200px) {
            background-image:  v-bind(contentBackgroundUrlCss) linear-gradient(45deg, #FF7878 0%, #FFC3C3 100%);
        }
    }

    &--default {
        background-image: linear-gradient(45deg, #01548B 0%, var(--global-primary-background) 100%);
        @media (min-width: 1200px) {
            background-image:  v-bind(contentBackgroundUrlCss) linear-gradient(45deg, #01548B 0%, var(--global-primary-background) 100%);
        }
    }

    // desktop doesnt change background, at all
    &--success, &--error, &--default {
        @media (min-width: 1200px) {
            background-repeat: no-repeat;
            background-size: cover;
            background-image: v-bind(contentBackgroundUrlCssDesktop);
        }
    }


}






