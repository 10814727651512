

.process-wrapper {
    margin: 80px auto 0 auto;
    background-color: var(--global-background);
    border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
    width: 100%;
    // full height - header - footer - margin from top
    min-height: calc(100vh - 60px - 60px - 80px);

    @media (max-width: 1200px) {
        margin-top: -60px; // cover header
        min-height: calc(100vh - 60px); // full height, except footer
        border-radius:0;
        width: 100vw;
        max-width: 100%;
        z-index: 20;
        right: 0;
    }

    @media (min-width: 1200px) {
        margin: 80px auto 80px auto;
        border-radius: var(--border-radius-large);
        width: 90%;
        max-width: 1000px;
        min-height: 70vh;
    }

    @media (min-width: 1300px) {
        max-width: 1200px;
    }
}

.process-wrapper-inner {
    padding: 0;
}

.process-title {
    @media (max-width: 1200px) {
        position: relative;
        padding: 22px;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.11);
        background-image: linear-gradient(99deg, #00376e -53%, #0284bb 91%);
        color: var(--global-background);
        display: flex;
        flex-direction: center;
        gap: 12px;
        line-height: 1;
        font-weight: bold;
        cursor: pointer;
        transition: opacity 300ms ease, box-shadow 300ms ease;

        .uk-icon { // correct for font offset
            content: ' ';
            position: absolute;
            top: 22px;
            inset-inline-start: 22px;
        }

        .process-title-text {
            flex: 100%;
            padding: 0 25px;
            text-align: center;
        }

        &:hover {
            opacity: 0.9;
            box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.21);
        }
    }

    @media (min-width: 1200px) {
        background-image: linear-gradient(93deg, #f2c94c 15%, #f2994a 129%);
        border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        padding: 20px 22px;
        line-height: 1;
        font-weight: bold;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.11);
        cursor: pointer;
        transition: opacity 300ms ease, box-shadow 300ms ease;

        .uk-icon { // correct for font offset
            position: relative;
            top: 2px;
        }

        &:hover {
            opacity: 0.9;
            box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.21);
        }

    }
}

.process-content-wrapper {
    @media (max-width: 1200px) {
        padding: 45px 20px 30px 20px;
    }

    @media (min-width: 1200px) {
        padding: 60px;
    }
}


